import React, { useState, useEffect, useRef } from "react";
import { Badge, Box, Button, Chip, Container, Grid, LinearProgress, Paper, Tooltip } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import bkstApi from "../../common/component/api";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import ListAltIcon from "@mui/icons-material/ListAlt";
import { getCustomerName, getQueryParam } from "../../common/component/util";
import { useParams, Link as RouterLink } from "react-router-dom";
import QuoteAdmin from "../../quote/screen/QuoteAdmin";
import RequestNotificationButton from "../component/RequestNotificationButton";
import RequestHeader from "../component/RequestHeader";
import SearchSummary from "../component/SearchSummary";
import RequestStatus from "../component/RequestStatus";
import CustomerStats from "../component/CustomerStats";
import GenerateAutoQuotes from "../component/GenerateAutoQuotes";
import moment from "moment";
import CloseRequest from "../component/CloseRequest";
import PersonAddOutlinedIcon from "@mui/icons-material/PersonAddOutlined";
import ContactDetailsButton from "../../common/component/ContactDetailsButton";
import GenerateAutoQuotesV2 from "../component/GenerateAutoQuotesV2";
import Gemini from "../../common/component/Gemini";
import { CheckOutlined } from "@mui/icons-material";

export default function RequestDetail(props) {
  const { reqId } = useParams();

  const [req, setReq] = useState();
  const refs = useRef({});

  const chatId = getQueryParam(window.location.search, "to");

  useEffect(() => {
    setReq();
    getData().then(() => {
      if (chatId) {
        const el = refs.current[chatId];
        if (el) {
          el.scrollIntoView();
        }
      }
    });
  }, [reqId, chatId]);

  const getData = () => {
    return bkstApi(`/admin/requests/${reqId}`).then((res) => {
      setReq(res.data);
      document.title = `Admin | ${res.data.title}`;
    });
  };

  const getTimeline = () => {
    return (req.events && req.events.map((e) => moment(e.ts).format(`MMM DD, h:mm a`)).join(" | ")) || "";
  };

  const getCustomerView = () => {
    bkstApi(`/admin/site/${req.bakerId || "mkt"}/redirect?path=/quote/${reqId}`);
  };

  if (!req) {
    return <LinearProgress />;
  } else {
    return (
      <Container maxWidth="sm">
        <Box mb={1} mt={2}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={4}>
              <ContactDetailsButton
                size="small"
                name={getCustomerName({ name: req.name, firstName: req.firstName, lastName: req.lastName })}
                phone={req.phone}
                email={req.email}
                verified={req.pv}
              />
              <Tooltip title={`${getTimeline("view")}`}>
                <IconButton onClick={getCustomerView}>
                  <Badge badgeContent={req.events && req.events.length} color="secondary">
                    <VisibilityOutlinedIcon />
                  </Badge>
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item xs={8} align="right">
              <Tooltip title="Add Quote">
                <IconButton component={RouterLink} to={`/request/${reqId}/quotes/add`}>
                  <PersonAddOutlinedIcon />
                </IconButton>
              </Tooltip>
              <CloseRequest reqId={reqId} title={req.title} onClose={() => window.location.reload()} />
              <RequestNotificationButton reqId={reqId} />
              { req.src === "mkt" && <GenerateAutoQuotesV2 req={req} /> }
              {req.orderId && (
                <span>
                  <Tooltip title="Edit Order">
                    <IconButton component={RouterLink} to={"/order/" + req.orderId}>
                      <ListAltIcon />
                    </IconButton>
                  </Tooltip>
                </span>
              )}
              <Tooltip title="Edit Request">
                <IconButton component={RouterLink} to={"/request/" + reqId + "/edit"}>
                  <EditOutlinedIcon />
                </IconButton>
              </Tooltip>
              <Gemini img={req.pics && req.pics[0]} />
            </Grid>
          </Grid>
        </Box>
        <RequestHeader request={req} />
        <Box my={1} align="center">
          {req.orderId && <Chip color="success" icon={<CheckOutlined />} label={`order`} size="small" />}
          <RequestStatus status={req.status} />
          {req.src && <Chip label={req.src} size="small" />}
          {req.type && <Chip label={req.type} size="small" />}
          {req.experiment && <Chip size="small" label={req.experiment} />}
          {req.utm && <Chip size="small" label={`${req.utm.utms}:${req.utm.utmc}`} />}
          <Chip size="small" icon={<AccessTimeOutlinedIcon />} label={moment(req.ts._seconds * 1000).fromNow()} />
        </Box>
        {req.customer && (
          <Paper elevation={0} style={{ backgroundColor: "#EEE" }}>
            <Box my={2} p={2}>
              <CustomerStats stats={req.customer} />
            </Box>
          </Paper>
        )}
        {req.src === "mkt" && <SearchSummary request={req} />}
        {req.quotes.map((value, index) => {
          return (
            <Box key={value.baker.id} ref={(element) => (refs.current[value.baker.id] = element)}>
              <QuoteAdmin quote={value} reqId={reqId} request={req} experiment={req.experiment} reqStatus={req.status} />
            </Box>
          );
        })}
        <Box>
          <Button
            disableElevation
            variant="outlined"
            color="secondary"
            fullWidth
            target="_blank"
            href={`https://console.firebase.google.com/project/bakestreet-f55a0/firestore/data/~2Frequest~2F${req.id}`}
          >
            View in DB
          </Button>
        </Box>
      </Container>
    );
  }
}

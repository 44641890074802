import React, { useEffect, useState } from "react";
import { Avatar, Box, Chip, Container, Grid, Link, LinearProgress, Paper, Typography } from "@mui/material";
import bkstApi from "../../common/component/api";
import { useParams, Link as RouterLink } from "react-router-dom";
import { BAKER_PIC_FOLDER } from "../../common/component/constants";
import currency from "currency.js";
import moment from "moment";

const FILTERS = [
  moment().subtract("2", "months").startOf("month").format("YYYY-MM-DD"),
  moment().subtract("1", "months").startOf("month").format("YYYY-MM-DD"),
  moment().startOf("month").format("YYYY-MM-DD")
];

export default function BakerReport() {
  const { bakerId } = useParams();

  const [filter, setFilter] = useState("");
  const [report, setReport] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (filter) {
      getData();
    }
  }, [bakerId, filter]);

  const getData = () => {
    setLoading(true);
    bkstApi(`/baker/${bakerId}/performance-report?filter=${filter}`).then((res) => {
      setReport(res.data);
      setLoading(false);
    });
  };

  if (loading) {
    return <LinearProgress />;
  } else {
    const collator = new Intl.Collator("en", { numeric: true, sensitivity: "base" });

    return (
      <Box>
        <Container maxWidth="md">
          <Box mt={7} align={"center"}>
            <Avatar
              alt=""
              src={`${BAKER_PIC_FOLDER}/${bakerId}/logo400.webp?now=${Date.now()}`}
              style={{ border: "2px solid #fff", width: "100px", height: "100px" }}
            />
          </Box>
          <Box mt={2} mb={6} align="center">
            {FILTERS.map((o, idx) => {
              return (
                <Chip
                  key={idx}
                  size="medium"
                  color={filter === o ? "primary" : "secondary"}
                  variant="outlined"
                  label={o}
                  clickable
                  component={Link}
                  onClick={() => setFilter(o)}
                />
              );
            })}
          </Box>
          {report && (
            <Box>
              <Box my={10} p={2} pt={4} sx={{ backgroundColor: "#ECECEC" }}>
                <Box mb={4} textAlign={"center"}>
                  <Typography variant="h4">{currency(report.orders.sales, { precision: 0 }).format()}</Typography>
                  <Typography variant="overline" color="textSecondary" display="block">
                    TOTAL SALES
                  </Typography>
                  <Typography variant="overline">
                    {report.orders.count} ORDERS • {currency(report.orders.tips, { precision: 0 }).format()} TIP
                  </Typography>
                </Box>
                {report.orders.segment.map((segment) => {
                  return (
                    <Box my={4}>
                      <Typography variant="overline" gutterBottom>
                        {segment.title}
                      </Typography>
                      <Grid container spacing={2}>
                        {Object.entries(segment.data)
                          .sort(([sdk1, sdv1], [sdk2, sdv2]) => collator.compare(sdk1, sdk2))
                          .map(([key, value]) => {
                            return (
                              <Grid item sm={3} xs={6}>
                                <Box p={2} sx={{ backgroundColor: "#FFF", textAlign: "center" }}>
                                  <Typography variant="body1">{currency(value.sales, { precision: 0 }).format()}</Typography>
                                  <Box>
                                    <Chip size="small" label={key} />
                                  </Box>
                                  <Typography variant="overline">
                                    {+value.count} ORDERS • {currency(value.tips, { precision: 0 }).format()} TIP
                                  </Typography>
                                </Box>
                              </Grid>
                            );
                          })}
                      </Grid>
                    </Box>
                  );
                })}
              </Box>
              <Box my={10} p={2} pt={4} sx={{ backgroundColor: "#ECECEC" }}>
                <Box mb={4} textAlign={"center"}>
                  <Typography variant="h4">{Math.round((report.quotes.conversion.orders / report.quotes.conversion.leads) * 100)}%</Typography>
                  <Typography variant="overline" color="textSecondary" display="block">
                    CONVERSION
                  </Typography>
                  <Typography variant="overline">
                    {report.quotes.conversion.leads} LEADS • {report.quotes.conversion.orders} ORDERS
                  </Typography>
                </Box>
                {report.quotes.segment.map((segment) => {
                  return (
                    <Box my={4}>
                      <Typography variant="overline" gutterBottom>
                        {segment.title}
                      </Typography>
                      <Grid container spacing={2}>
                        {Object.entries(segment.data)
                          .sort(([sdk1, sdv1], [sdk2, sdv2]) => collator.compare(sdk1, sdk2))
                          .map(([key, value]) => {
                            return (
                              <Grid item sm={3} xs={6}>
                                <Box p={2} sx={{ backgroundColor: "#FFF", textAlign: "center" }}>
                                  <Typography variant="body1">{Math.round((value.orders / value.count) * 100)}%</Typography>
                                  <Box>
                                    <Chip size="small" label={key} />
                                  </Box>
                                  <Typography variant="overline">
                                    {+value.count} LEADS • {value.orders} ORDERS
                                  </Typography>
                                </Box>
                              </Grid>
                            );
                          })}
                      </Grid>
                    </Box>
                  );
                })}
              </Box>
              <Box my={10} textAlign={"left"}>
                <Typography variant="overline" color="textSecondary">
                  UNANSWERED CHAT
                </Typography>
                <Paper elevation={0}>
                  <Box p={2}>
                    {report.custChats.map((o) => (
                      <Box>
                        <Link component={RouterLink} to={`/request/${o}`} underline="hover">
                          {o}
                        </Link>
                      </Box>
                    ))}
                  </Box>
                </Paper>
              </Box>
              <Box my={10} textAlign={"left"}>
                <Typography variant="overline" color="textSecondary">
                  NO RESPONSE
                </Typography>
                <Paper elevation={0}>
                  <Box p={2}>
                    {report.unanswered.map((o) => (
                      <Box>
                        <Link component={RouterLink} to={`/request/${o}`} underline="hover">
                          {o}
                        </Link>
                      </Box>
                    ))}
                  </Box>
                </Paper>
              </Box>
            </Box>
          )}
          <pre style={{ fontSize: "10px", color: "#FFF", backgroundColor: "#000", textAlign: "left" }}>{JSON.stringify(report, null, 2)}</pre>
        </Container>
      </Box>
    );
  }
}

import React, { useEffect, useState } from "react";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Link,
  LinearProgress,
  Paper,
  Rating,
  Tab,
  Tabs,
  Tooltip,
  Typography
} from "@mui/material";
import BakerStatus from "../component/BakerStatus";
import IconText from "../../common/component/IconText";
import { AlternateEmail, Cake, Fingerprint, Instagram, PhoneAndroid, Person, Phone, Room } from "@mui/icons-material";
import bkstApi from "../../common/component/api";
import ReviewAdmin from "../../common/component/review/ReviewAdmin";
import ReviewAdd from "../../common/component/review/ReviewAdd";
import PicUploadMultiple from "../../common/component/PicUploadMultiple";
import ClickToCopy from "../../common/component/ClickToCopy";
import { useParams, Link as RouterLink } from "react-router-dom";
import ProductSearch from "../../product/screen/ProductSearch";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import AdminBakerEditFlavors from "../../baker/component/AdminBakerEditFlavors";
import { BAKER_PIC_FOLDER } from "../../common/component/constants";
import moment from "moment";
import AdminChatWithBaker from "../../baker/component/AdminChatWithBaker";
import Row from "../../product/component/ProductDetail";
import BakerOrders from "../component/BakerOrders";
import BakerQuotes from "../component/BakerQuotes";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import { useLocation } from "react-router-dom";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import NotificationIcon from "../../notification/component/NotificationIcon";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { useNavigate } from "react-router";
import { sortNotifications } from "../../common/component/util";
import { getQueryParam } from "../../common/component/util";
import BakerStaff from "../component/BakerStaff";
import OnlineShop from "../../product/screen/OnlineShop";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";

export default function BakerDetail(props) {
  let location = useLocation();
  const navigate = useNavigate();

  const { bakerId } = useParams();

  const [baker, setBaker] = useState();
  const [publicData, setPublicData] = useState();
  const [privateData, setPrivateData] = useState();

  const [notifications, setNotifications] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [staff, setStaff] = useState([]);
  const [tab, setTab] = useState("");

  const [recentUploads, setRecentUploads] = useState([]);

  useEffect(() => {
    getData();

    let [tab] = [getQueryParam(window.location.search, "tab")];
    if (tab) {
      setTab(Number(tab));
    }
  }, [bakerId, location.key]);

  useEffect(() => {
    if (tab === 3) {
      bkstApi(`/admin/baker/${bakerId}/notification`).then((res) => {
        setNotifications(res.data.notifications.sort(sortNotifications));
      });
    } else if (tab === 6) {
      bkstApi(`/admin/baker/${bakerId}/staff`).then((res) => {
        setStaff(res.data.staff);
      });
    }
  }, [tab]);

  const getData = () => {
    bkstApi(`/admin/bakers/${bakerId}`).then((res) => {
      setBaker(res.data.baker);
      setPublicData(res.data.public);
      setPrivateData(res.data.private);
      setReviews(res.data.reviews);
    });
  };

  const generateProducts = (images) => {
    bkstApi.post(`/admin/bakers/${bakerId}/products`, { images }).then((res) => {
      setRecentUploads((arr) => [...res.data.photos, ...recentUploads]);
    });
  };

  const approve = () => {
    bkstApi.put(`/baker/${bakerId}/approve`).then((res) => {
      getData();
    });
  };

  const addNewReview = (review) => {
    setReviews([review, ...reviews]);
  };

  if (!baker || !publicData || !privateData) {
    return <LinearProgress />;
  } else {
    return (
      <Box my={2}>
        <Container maxWidth="md">
          <Box>
            <Grid container spacing={2} alignItems="center">
              <Grid item sm={6} xs={4}>
                <Tooltip title="View in DB">
                  <IconButton
                    target="_blank"
                    href={`https://console.firebase.google.com/project/bakestreet-f55a0/firestore/data/~2Fbaker~2F${bakerId}`}
                  >
                    <Fingerprint />
                  </IconButton>
                </Tooltip>
                <IconButton
                  target="_blank"
                  href={`https://console.firebase.google.com/u/0/project/bakestreet-f55a0/storage/bakestreet-f55a0.appspot.com/files/~2Fimg~2Fsite~2F${bakerId}`}
                >
                  <CloudUploadOutlinedIcon />
                </IconButton>
              </Grid>
              <Grid item sm={6} xs={8} align="right">
                <AdminChatWithBaker name={baker.name} bakerId={bakerId} />
                <Tooltip title="Report">
                  <IconButton component={RouterLink} to={`/baker/${bakerId}/report`}>
                    <AssessmentOutlinedIcon />
                  </IconButton>
                </Tooltip>
                {baker && baker.status === "new" && (
                  <Tooltip title="Approve Baker">
                    <IconButton onClick={approve}>
                      <PowerSettingsNewIcon />
                    </IconButton>
                  </Tooltip>
                )}
                {/* <Tooltip title="Pricing Model">
                  <IconButton component={RouterLink} to={`/baker/${bakerId}/config/pricing`}>
                    <AttachMoneyOutlinedIcon />
                  </IconButton>
                </Tooltip> */}
                <Tooltip title="Edit Baker">
                  <IconButton component={RouterLink} to={`/baker/${bakerId}/edit`}>
                    <EditOutlinedIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Box>
          <Paper elevation={0} style={{ backgroundColor: "#EEE" }}>
            <Box p={2}>
              <Grid container spacing={3}>
                <Grid item xs={3} sm={3} align="center">
                  <Badge
                    overlap="circular"
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right"
                    }}
                    style={{ width: "100%", height: "auto" }}
                    badgeContent={
                      <Avatar
                        alt=""
                        src={`${BAKER_PIC_FOLDER}/${baker.id}/photo400.webp?now=${Date.now()}`}
                        style={{ border: "2px solid #fff", width: "50px", height: "50px" }}
                      />
                    }
                  >
                    <img
                      alt={baker.bizName}
                      src={`${BAKER_PIC_FOLDER}/${baker.id}/logo400.webp?now=${Date.now()}`}
                      style={{ width: "100%", height: "auto", border: "1px solid #FFF" }}
                    />
                  </Badge>
                </Grid>
                <Grid item xs={9} sm={9}>
                  <Box>
                    <Typography variant="h5">
                      {baker.bizName}&nbsp;
                      <BakerStatus status={baker.status} />
                    </Typography>
                    <Box mb={1}>
                      <Grid container spacing={1} alignItems="center">
                        <Grid item>
                          <Rating size="small" name="rating" precision={0.25} value={Number(baker.rating)} readOnly />
                        </Grid>
                        <Grid item>
                          <Typography variant="caption" color="textSecondary">
                            {baker.rating}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box style={{ color: "#777" }} mb={1}>
                      <IconText
                        icon={Cake}
                        variant="body2"
                        text={`${baker.orders} Orders ${baker.lo ? `(recent: ${moment(baker.lo).fromNow()})` : ""}`}
                      />
                      <IconText icon={Person} variant="body2" text={`${privateData.firstName} ${privateData.lastName}`} />
                      <IconText
                        icon={Room}
                        variant="body2"
                        text={`${privateData.street}, ${baker.location.city}, ${baker.location.state} ${baker.location.zip}`}
                      />
                    </Box>
                    <Box>
                      {publicData.ig && (
                        <IconButton size="small" target="_blank" href={publicData.ig}>
                          <Instagram />
                        </IconButton>
                      )}
                      <ClickToCopy icon={AlternateEmail} text={privateData.email} />
                      <ClickToCopy icon={Phone} text={privateData.phone} />
                      <ClickToCopy icon={PhoneAndroid} text={privateData.mobile} />
                    </Box>
                    {/* <Box my={1}>
                      {privateData.tags &&
                        privateData.tags.map((t, i) => {
                          return <Chip key={i} size="small" label={t} />;
                        })}
                      </Box> */}
                  </Box>
                </Grid>
              </Grid>
              {/* <Box my={1}>
                <TextWithReadMore data={publicData.bio} limit="140" />
                    </Box> */}
            </Box>
          </Paper>
          <Box sx={{ border: "1px solid #EEE" }}>
            <Tabs value={tab} onChange={(e, v) => setTab(v)} variant="scrollable" scrollButtons="auto" allowScrollButtonsMobile>
              <Tab label="Quotes" />
              <Tab label="Orders" />
              <Tab label="Shop" />
              <Tab label="Notifications" />
              <Tab label="Profile" />
              <Tab label="Reviews" />
              <Tab label="Staff" />
            </Tabs>
          </Box>
          <Box>
            {tab === 0 && (
              <Grid container>
                <Grid item sm={12}>
                  <BakerQuotes bakerId={bakerId} />
                </Grid>
              </Grid>
            )}
            {tab === 1 && (
              <Grid container>
                <Grid item sm={12}>
                  <BakerOrders bakerId={bakerId} />
                </Grid>
              </Grid>
            )}
            {tab === 2 && <OnlineShop siteId={bakerId} />}
            {tab === 3 && (
              <Box>
                <Box mt={2} mb={4} textAlign="center">
                  <Typography variant="h2">{notifications.filter((item) => item.status === "open").length}</Typography>
                  <Typography variant="overline" color="textSecondary">
                    UNREAD NOTIFICATIONS
                  </Typography>
                </Box>
                {notifications.map((item, idx) => (
                  <Paper key={idx} elevation={0}>
                    <Box p={2} my={2}>
                      <Box sx={{ display: "flex", gap: "10px", width: "100%", opacity: item.status === "open" ? 1 : 0.5 }}>
                        <Box pt={0.5}>
                          <Badge badgeContent={item.count} color="secondary" overlap="circular">
                            <NotificationIcon type={item.heading} priority={item.priority} />
                          </Badge>
                        </Box>
                        <Box sx={{ flexGrow: "1" }}>
                          <Typography variant="body2">{item.heading}</Typography>
                          <Typography
                            title={item.text}
                            variant="body2"
                            color="textSecondary"
                            style={{ whiteSpace: "normal", maxHeight: "1.2rem", overflow: "hidden", textOverflow: "ellipsis" }}
                          >
                            {item.text}
                          </Typography>
                          <Box mt={0.5} style={{ fontSize: ".8rem", color: "#888" }}>
                            <Grid container alignItems="center">
                              <Grid item>
                                <AccessTimeOutlinedIcon style={{ fontSize: "14px", marginRight: "5px" }} />
                              </Grid>
                              <Grid item>{`${moment(item.ts._seconds * 1000).fromNow()}`}</Grid>
                            </Grid>
                          </Box>
                        </Box>
                        <Box>
                          <IconButton onClick={() => navigate(item.link.replace("/my", ""))}>
                            <VisibilityOutlinedIcon />
                          </IconButton>
                        </Box>
                      </Box>
                    </Box>
                  </Paper>
                ))}
              </Box>
            )}
            {tab === 4 && (
              <Box my={4}>
                <Grid container spacing={4}>
                  <Grid item sm={12} xs={12}>
                    <Typography variant="overline" color="textSecondary">
                      Links
                    </Typography>
                    <Paper>
                      <Box px={4} py={2} sx={{ display: "flex", gap: "10px", fontSize: "12px" }}>
                        {baker && baker.handle && (
                          <Link component="a" href={`${process.env.REACT_APP_PUBLIC_VIEW_BASE_URL}/shop/${baker.handle}`} target="_blank">
                            BakeStreet Shop
                          </Link>
                        )}
                        <Link
                          component="a"
                          target="_blank"
                          href={`https://console.firebase.google.com/u/0/project/bakestreet-f55a0/storage/bakestreet-f55a0.appspot.com/files/~2Fimages~2Fbakers~2F${baker.id}~2Fportfolio`}
                        >
                          Marketplace Photos
                        </Link>
                      </Box>
                    </Paper>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <AdminBakerEditFlavors data={publicData} bakerId={bakerId} />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <Paper>
                      <Box pb={6}>
                        <Box sx={{ textAlign: "right" }}>
                          <PicUploadMultiple
                            id="pics"
                            title="Add"
                            onComplete={(images) => generateProducts(images)}
                            folder={`img/tmp`}
                            color="secondary"
                          />
                        </Box>
                        {recentUploads && recentUploads.length > 0 && (
                          <Box px={2} pt={2} mb={4} style={{ border: "1px dashed #CCC" }} align="center">
                            <Box mb={2}>
                              <Typography variant="caption" color="textSecondary">
                                {`${recentUploads.length} photos uploaded`}
                              </Typography>
                            </Box>
                            <Grid container spacing={2} justifyContent="center">
                              {recentUploads.map((value, index) => {
                                return (
                                  <Grid item sm={1} xs={3} key={index}>
                                    <Row product={value} />
                                  </Grid>
                                );
                              })}
                            </Grid>
                          </Box>
                        )}
                        <ProductSearch bakerId={bakerId} />
                      </Box>
                    </Paper>
                  </Grid>
                </Grid>
              </Box>
            )}
            {tab === 5 && (
              <Box>
                <ReviewAdd baker={baker} onDone={addNewReview} />
                <Grid container spacing={2}>
                  {reviews.map((el) => {
                    return (
                      <Grid item sm={6} xs={12} key={el.id}>
                        <ReviewAdmin key={el.id} data={el} baker={baker} />
                      </Grid>
                    );
                  })}
                </Grid>
              </Box>
            )}
            {tab === 6 && (
              <Box p={2} mt={5}>
                <BakerStaff acctId={bakerId} staff={staff} />
              </Box>
            )}
          </Box>
        </Container>
      </Box>
    );
  }
}

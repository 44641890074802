import React, { useState, useEffect } from "react";
import { Box, Container, Chip, Grid, LinearProgress, Link, Typography, Avatar } from "@mui/material";
import bkstApi from "../../common/component/api";
import OrderSummary from "../component/OrderSummary";
import { Link as RouterLink } from "react-router-dom";
import currency from "currency.js";
import moment from "moment";
import { sortByOrderDateTime } from "../../common/component/util";
import { STORAGE_BUCKET } from "../../common/component/constants";
import RevealOnScroll from "../../common/component/animate/RevealOnScroll";
import CountUp from "../../common/component/animate/CountUp";

const FILTERS = ["recent", "upcoming", "open"];

export default function OrderDashboard(props) {
  const [filter, setFilter] = useState("recent");
  const [fromDt, setFromDt] = useState("");
  const [toDt, setToDt] = useState("");

  const [orders, setOrders] = useState([]);
  const [summary, setSummary] = useState();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let from = "";
    let to = "";

    if (filter === "recent") {
      from = moment().subtract(1, "days").format("YYYY-MM-DD");
    } else if (filter === "open") {
    } else if (filter === "upcoming") {
      from = moment().format("YYYY-MM-DD");
      to = moment().add(2, "days").format("YYYY-MM-DD");
    } else {
      from = moment().format("YYYY-MM-DD");
      to = moment().add(28, "days").endOf("isoweek").format("YYYY-MM-DD");
    }

    setFromDt(from);
    setToDt(to);

    search(from, to);
  }, [filter]);

  const search = (from, to) => {
    setLoading(true);

    bkstApi.get(`/admin/order?filter=${filter}&fromDt=${from}&toDt=${to}`).then((res) => {
      if (filter === "recent") {
        //sort to make pending order appear on top
        setOrders(res.data.sort((a, b) => (a.status === "pending" ? -1 : 1)));
      } else {
        setOrders(res.data.sort(sortByOrderDateTime));
      }

      if (res.data) {
        let smry = {
          mkt: { online: { count: 0, sales: 0 }, offline: { count: 0, sales: 0 } },
          saas: { online: { count: 0, sales: 0 }, offline: { count: 0, sales: 0 } },
          leaderboard: {}
        };
        for (const o of res.data) {
          if (o.test || o.test === "true") {
            continue;
          }
          if (o.src === "mkt") {
            let data = smry["mkt"] || { online: { count: 0, sales: 0 } };
            data.online.count = +data.online.count + 1;
            data.online.sales = +data.online.sales + +o.payment.total;
            smry["mkt"] = data;
          } else if (o.src === "direct") {
            let data = smry["saas"] || { online: { count: 0, sales: 0 }, offline: { count: 0, sales: 0 } };
            let dataByAcct = smry["leaderboard"]?.[o.baker.id] || { online: { count: 0, sales: 0 }, offline: { count: 0, sales: 0 } };

            if (o.paymentType === "offline") {
              data.offline.count = +data.offline.count + 1;
              data.offline.sales = +data.offline.sales + +o.payment.total;

              dataByAcct.offline.count = +dataByAcct.offline.count + 1;
              dataByAcct.offline.sales = +dataByAcct.offline.sales + +o.payment.total;
            } else {
              data.online.count = +data.online.count + 1;
              data.online.sales = +data.online.sales + +o.payment.total;

              dataByAcct.online.count = +dataByAcct.online.count + 1;
              dataByAcct.online.sales = +dataByAcct.online.sales + +o.payment.total;
            }

            smry["saas"] = data;
            smry["leaderboard"][o.baker.id] = dataByAcct;
          }
        }

        smry.leaderboard = Object.entries(smry.leaderboard)
          .map(([k, v]) => {
            return {
              id: k,
              ...v
            };
          })
          .sort((o1, o2) => +o2.online.sales - +o1.online.sales);
        setSummary(smry);
      }

      setLoading(false);
    });
  };

  if (loading) {
    return <LinearProgress />;
  } else {
    return (
      <Box my={7}>
        <Container maxWidth="sm">
          <Box my={4} align="center">
            <Typography variant="h1" gutterBottom>
              Orders
            </Typography>
            {FILTERS.map((o, idx) => {
              return (
                <Chip
                  key={idx}
                  size="medium"
                  color={filter === o ? "primary" : "secondary"}
                  variant="outlined"
                  label={o}
                  clickable
                  component={Link}
                  onClick={() => setFilter(o)}
                />
              );
            })}
          </Box>
          {summary && (
            <Box my={2} p={2} align="center">
              <Box sx={{ display: "flex", gap: "15px" }}>
                <Box p={2} sx={{ backgroundColor: "#EEE", flexGrow: 1 }}>
                  <Typography variant="overline" color="textSecondary">
                    MKT
                  </Typography>
                  <Box sx={{ display: "flex", gap: "10px", justifyContent: "center" }}>
                    <CountUp from={0} to={summary.mkt.online.sales} currencyOptions={{ precision: 0 }}></CountUp>
                    <span>#{summary.mkt.online.count}</span>
                  </Box>
                </Box>
                <Box p={2} sx={{ backgroundColor: "#EEE", flexGrow: 1 }}>
                  <Typography variant="overline" color="textSecondary">
                    SAAS
                  </Typography>
                  <Box sx={{ display: "flex", gap: "10px", justifyContent: "center" }}>
                    <CountUp from={0} to={summary.saas.online.sales} currencyOptions={{ precision: 0 }}></CountUp>
                    <span>#{summary.saas.online.count}</span>
                  </Box>
                  <Typography variant="overline" color="error">
                    {currency(summary.saas.offline.sales, { precision: 0 }).format()} #{summary.saas.offline.count}
                  </Typography>
                </Box>
              </Box>
            </Box>
          )}
          <Box my={2} sx={{ display: "flex", gap: "15px", overflowX: "auto", maxWidth: "85vw" }}>
            {summary.leaderboard.map((o) => (
              <Box py={2} sx={{ textAlign: "center" }}>
                <Link component={RouterLink} to={`/baker/${o.id}`}>
                  <Avatar src={`${STORAGE_BUCKET}/img/b/${o.id}/logo400.webp`} sx={{ width: "50px", height: "50px" }} />
                </Link>
                <Box py={0.3}>
                  <Typography sx={{ fontSize: "9px" }}>
                    {currency(o.online.sales, { precision: 0 }).format()} ({o.online.count})
                  </Typography>
                </Box>
                {+o.offline.count > 0 && (
                  <Box>
                    <Typography sx={{ fontSize: "9px", color: "red" }}>
                      {currency(o.offline.sales, { precision: 0 }).format()} ({o.offline.count})
                    </Typography>
                  </Box>
                )}
              </Box>
            ))}
          </Box>
          {orders.map((o) => {
            return (
              <Box mb={2} key={o.id}>
                <Link underline="none" component={RouterLink} to={`/order/${o.id}`} href="#">
                  <RevealOnScroll>
                    <OrderSummary order={o} />
                  </RevealOnScroll>
                </Link>
              </Box>
            );
          })}
        </Container>
      </Box>
    );
  }
}

import { animate } from "framer-motion";
import React, { useEffect, useRef } from "react";
import currency from "currency.js";

export default function CountUp({ from, to, currencyOptions }) {
  const nodeRef = useRef();

  useEffect(() => {
    const node = nodeRef.current;

    const controls = animate(from, to, {
      duration: 1,
      onUpdate(value) {
        if (currencyOptions) {
          node.textContent = currency(value.toFixed(2), { precision: currencyOptions.precision }).format();
        } else {
          node.textContent = value.toFixed(2);
        }
      }
    });

    return () => controls.stop();
  }, [from, to]);

  return <div ref={nodeRef} />;
}

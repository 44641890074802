import React, { useContext } from "react";
import { AppBar, Box, Hidden, IconButton, Toolbar } from "@mui/material";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import LinkIcon from '@mui/icons-material/Link';
import QuickSearch from "./QuickSearch";
import UserLoggedInButton from "./UserLoggedInButton";
import ConfigContext from "../context/ConfigContext";
import ClickToCopy from "../ClickToCopy";
import Notifications from "../../../notification/component/Notifications";

function Header(props) {
  const ctx = useContext(ConfigContext);
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <AppBar position="static" style={{ background: "#FFF", boxShadow: "none", borderBottom: "1px solid #eee" }}>
        <Toolbar style={{ display: "flex", justifyContent: "space-between", gap: "15px" }}>
          <Box>
            <Hidden smUp>
              <IconButton aria-label="open drawer" edge="start" onClick={props.toggleMobileDrawer}>
                <MenuRoundedIcon />
              </IconButton>
            </Hidden>
          </Box>
          <Box style={{ display: "flex", justifyContent: "flex-end", flexGrow: "1" }}>
            <QuickSearch />
            <ClickToCopy icon={LinkIcon} text={window.location.href} />
            {ctx && <Notifications/>}
            {ctx && <UserLoggedInButton user={ctx.user} />}
          </Box>
        </Toolbar>
      </AppBar>
    </div>
  );
}
export default Header;

import React, { useState, useEffect } from "react";
import { Box, Button, Container, Chip, Grid, LinearProgress, Link, Typography, Avatar, Paper } from "@mui/material";
import bkstApi from "../../common/component/api";
import { Link as RouterLink } from "react-router-dom";
import currency from "currency.js";
import moment from "moment";
import { sortByOrderDateTime } from "../../common/component/util";
import { STORAGE_BUCKET } from "../../common/component/constants";
import CountUp from "../../common/component/animate/CountUp";

export default function Mehr(props) {
  const [filter, setFilter] = useState("recent");
  const [fromDt, setFromDt] = useState("");
  const [toDt, setToDt] = useState("");

  const [data, setData] = useState([]);

  const [orders, setOrders] = useState([]);
  const [summary, setSummary] = useState();

  return (
    <Box my={7}>
      <Container sx={{ textAlign: "center" }}>
        <Typography sx={{ fontSize: "70px", fontWeight: "700", color: "green" }}>🎄 Mehr</Typography>
        <Box>
          <Button variant="contained" component={RouterLink} to={`/order`}>
            GO TO CAKES APP
          </Button>
        </Box>
      </Container>
    </Box>
  );
}

import React, { useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Avatar,
  Box,
  Backdrop,
  Button,
  CircularProgress,
  Chip,
  Container,
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  Tooltip,
  Typography,
  Link
} from "@mui/material";
import bkstApi from "../../common/component/api";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { Link as RouterLink } from "react-router-dom";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import OrderDetails from "../component/OrderDetails";
import OrderFulfillment from "../component/OrderFulfillment";
import OrderStatus from "../component/OrderStatus";
import OrderProxyNumberAdmin from "../component/OrderProxyNumberAdmin";
import OrderNotificationButton from "../component/OrderNotificationButton";
import ListAltIcon from "@mui/icons-material/ListAlt";
import Invoices from "../component/Invoices";
import Images from "../../common/component/Images";
import moment from "moment";
import CreditCardOutlinedIcon from "@mui/icons-material/CreditCardOutlined";
import ReviewAdmin from "../../common/component/review/ReviewAdmin";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import OrderPayment from "../component/OrderPayment";
import { BAKER_PIC_FOLDER } from "../../common/component/constants";
import OrderChat from "../component/OrderChat";
import ContactDetailsButton from "../../common/component/ContactDetailsButton";
import OrderProgress from "../component/OrderProgress";
import KitchenInstructions from "../component/KitchenInstructions";
import OrderPrint from "../component/OrderPrint";
import { getCustomerName } from "../../common/component/util";
import CustomerStats from "../../request/component/CustomerStats";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import ConfigContext from "../../common/component/context/ConfigContext";
import { useNavigate } from "react-router";
import { Timeline, TimelineContent, TimelineConnector, TimelineSeparator } from "@mui/lab";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import { ExpandMoreOutlined } from "@mui/icons-material";

export default function Order(props) {
  const ctx = useContext(ConfigContext);
  const navigate = useNavigate();

  const { orderId } = useParams();
  const [order, setOrder] = useState();
  const [history, setHistory] = useState("");

  const [showPayBtn, setShowPayBtn] = useState(false);
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    setOrder();
    bkstApi(`/admin/order/${orderId}`).then((res) => {
      setOrder(res.data);

      if (!res.data.transferId) {
        if (res.data.src === "mkt") {
          setShowPayBtn(res.data.status === "fulfilled");
        } else if (res.data.src === "direct") {
          setShowPayBtn(true);
        }
      }
    });
  }, [orderId]);

  const chargeBalancePayment = () => {
    if (window.confirm("Manually charge balance payment?")) {
      setProcessing(true);
      bkstApi.put(`/admin/order/${orderId}/charge-balance-payment`).then((res) => {
        window.location.reload();
      });
    }
  };

  const getHistory = () => {
    return bkstApi(`/admin/site/${order.baker.id}/order/${orderId}/history`).then((res) => {
      let arr = [];
      for (let o of res.data.history) {
        if (!o.updTs) {
          o.updTs = o.ts._seconds * 1000;
        }
        arr.push(o);
      }
      arr.sort((o1, o2) => +o2.updTs - +o1.updTs);

      setHistory(arr);
    });
  };

  const recoverFundsFromConnect = () => {
    if (window.confirm("ALERT: Don't do this unless you're sure funds haven't been pulled before")) {
      setProcessing(true);
      bkstApi.put(`/admin/order/${orderId}/recover-funds-from-connect`).then((res) => {
        setProcessing(false);
        window.confirm(res.data.transferId);
      });
    }
  };

  const createMetrobiDelivery = () => {
    setProcessing(true);
    bkstApi.post(`/admin/order/${orderId}/metrobi`).then((res) => {
      navigate(0);
    });
  };

  if (!order) {
    return <LinearProgress />;
  } else {
    const showEditButton = order.src === "mkt";

    return (
      <Container maxWidth="sm">
        <Box my={4}>
          <Box p={2} align="center">
            <Chip size="small" color="secondary" label={order.status.toUpperCase()} />
            {order.reviewId && <Chip size="small" color="secondary" label={"reviewed"} />}
            {order.src && <Chip label={order.src} size="small" />}
            {order.reqType && <Chip label={order.reqType} size="small" />}
            {order.utm && <Chip size="small" label={`${order.utm.utms}:${order.utm.utmc}`} />}
            <Chip icon={<ShoppingBagOutlinedIcon />} size="small" label={`${moment(order.ts._seconds * 1000).fromNow()}`} />
          </Box>
          {order.src === "mkt" && !order.proxySession && (
            <Box mb={1}>
              <Alert severity="error">No proxy session generated for marketplace order</Alert>
            </Box>
          )}
          {order.customer.history && (
            <Box>
              <Typography color="textSecondary" variant="overline">
                HISTORY
              </Typography>
              <Paper elevation={0} style={{ backgroundColor: "#EEE" }}>
                <Box mb={4} p={2}>
                  <CustomerStats stats={order.customer.history} />
                </Box>
              </Paper>
            </Box>
          )}
          <Grid container alignItems="center">
            <Grid item xs={12} sm={3}>
              <ContactDetailsButton size="small" name={getCustomerName(order.customer)} phone={order.customer.phone} email={order.customer.email} />
            </Grid>
            <Grid item xs={12} sm={9} align="right">
              <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                <OrderNotificationButton reqId={order.reqId} orderId={order.id} />
                <OrderStatus orderId={order.id} title={order.details.title} status={order.status} />
                {order.proxySession && <OrderProxyNumberAdmin orderId={order.id} proxy={order.proxySession.number} />}
                <OrderChat orderId={order.id} name={order.customer.name} chat={order.customer.chat} />
                &nbsp;|&nbsp;
                {order.reqId && (
                  <Tooltip title="Request">
                    <IconButton component={RouterLink} to={`/request/${order.reqId}`}>
                      <ListAltIcon />
                    </IconButton>
                  </Tooltip>
                )}
                <Tooltip title="View Order">
                  <IconButton href={`${process.env.REACT_APP_PUBLIC_VIEW_BASE_URL}/order/${orderId}`} target="_blank">
                    <VisibilityOutlinedIcon />
                  </IconButton>
                </Tooltip>
                {showEditButton && (
                  <Tooltip title="Edit">
                    <IconButton component={RouterLink} to={`/order/${orderId}/edit`}>
                      <EditOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </Box>
            </Grid>
          </Grid>
          <OrderDetails order={order} />
        </Box>
        <Box my={6}>
          <Grid container style={{ marginBottom: "5px", alignItems: "center" }}>
            <Grid item xs={6}>
              <Typography variant="overline" color="textSecondary">
                INSTRUCTIONS
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Box sx={{ display: "flex", gap: "4px", alignItems: "center", justifyContent: "flex-end" }}>
                <OrderProgress progress={order.progress} orderId={order.id} />
                <OrderPrint order={order} />
              </Box>
            </Grid>
          </Grid>
          <KitchenInstructions data={order.kitchen} />
        </Box>
        <Box my={4}>
          <Grid container alignItems="center">
            <Grid item xs={6}>
              <Typography variant="overline">{order.fulfillment.type} DETAILS</Typography>
            </Grid>
            <Grid item xs={6} align="right">
              <Tooltip title="Edit Fulfillment">
                <IconButton component={RouterLink} to={`/order/${orderId}/fulfillment/edit`}>
                  <EditOutlinedIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
          <OrderFulfillment type={order.fulfillment.type} order={order.fulfillment} />
          {order.fulfillment.type === "delivery" &&
            order.fulfillment.provider &&
            order.fulfillment.provider.name === "metrobi" &&
            !order.fulfillment.provider.deliveryId && (
              <Button variant="outlined" color="error" fullWidth onClick={createMetrobiDelivery}>
                CREATE METROBI DELIVERY
              </Button>
            )}
        </Box>
        {order.invoice && <Invoices orderId={order.id} data={order.invoice} />}
        <Box my={4}>
          <Grid container alignItems="center">
            <Grid item xs={6}>
              <Typography variant="overline">PAYMENT </Typography>
              {order.paymentType && <Chip size="small" color="secondary" label={order.paymentType} />}
            </Grid>
            <Grid item xs={6} align="right">
              {order.cid && +order.payment.due > 0 && (
                <Tooltip title="Charge Balance Payment">
                  <IconButton onClick={chargeBalancePayment} disabled={processing}>
                    <ErrorOutlineIcon />
                  </IconButton>
                </Tooltip>
              )}
              {order.cid && (
                <Tooltip title="Customer's card">
                  <IconButton component="a" target="_blank" href={`https://dashboard.stripe.com/customers/${order.cid}`}>
                    <CreditCardOutlinedIcon />
                  </IconButton>
                </Tooltip>
              )}
              {/* <Tooltip title="Send payment">
              <IconButton component={RouterLink} to={`/my/order/${order.id}/get-paid`} disabled={!showPayBtn}>
                <AccountBalanceWalletOutlinedIcon />
              </IconButton>
            </Tooltip> */}
              <Tooltip title="Edit Payment">
                <IconButton component={RouterLink} to={`/order/${orderId}/payment/edit`}>
                  <EditOutlinedIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
          <OrderPayment order={order} />
        </Box>
        <Box my={4}>
          <Typography variant="overline" color="textSecondary">
            CAKE ARTIST
          </Typography>
          <Paper elevation={0}>
            <Box p={2}>
              <Link component={RouterLink} to={`/baker/${order.baker.id}`} style={{ color: "inherit" }}>
                <Avatar alt="" src={`${BAKER_PIC_FOLDER}/${order.baker.id}/photo400.webp`} style={{ width: "40px", height: "40px" }} />
              </Link>
            </Box>
          </Paper>
        </Box>
        {order.finalPics && (
          <Box>
            <Typography variant="caption">PAYMENT SENT ({moment(order.transferTs).fromNow()})</Typography>
            <Paper elevation={0}>
              <Box p={4}>
                <Images pics={order.finalPics} edit={true} />
              </Box>
            </Paper>
          </Box>
        )}
        {order.review && (
          <Box my={6}>
            <ReviewAdmin data={order.review} baker={order.baker} />
          </Box>
        )}
        <Box my={4}>
          <Accordion
            elevation={0}
            onChange={(e, expanded) => {
              if (expanded) {
                getHistory();
              }
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreOutlined />}>History</AccordionSummary>
            <AccordionDetails sx={{ padding: 0 }}>
              <Box>
                {history && (
                  <Timeline
                    sx={{
                      [`& .${timelineItemClasses.root}:before`]: {
                        flex: 0,
                        padding: 0
                      }
                    }}
                  >
                    {history.map((o) => {
                      return (
                        <TimelineItem>
                          <TimelineSeparator>
                            <Avatar src={`https://storage.googleapis.com/bakestreet-f55a0.appspot.com/img/u/${o.uid}.webp`} />
                            <TimelineConnector />
                            <TimelineConnector />
                          </TimelineSeparator>
                          <TimelineContent ml={1} mb={4} sx={{ backgroundColor: "#F7F7F7" }}>
                            <Box mb={1}>
                              <Typography variant="body2" display="block">
                                {moment(o.updTs).format("LLLL")}
                              </Typography>
                              <Chip size="small" label={o.status} />
                              <Box my={2}>
                                <OrderDetails order={o} />
                              </Box>
                              <Box my={2}>
                                <OrderFulfillment order={o.fulfillment} />
                              </Box>
                            </Box>
                          </TimelineContent>
                        </TimelineItem>
                      );
                    })}
                  </Timeline>
                )}
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>
        <Box>
          <Button
            disableElevation
            color="secondary"
            variant="contained"
            fullWidth
            target="_blank"
            href={`https://console.firebase.google.com/project/bakestreet-f55a0/firestore/data/~2Forder~2F${orderId}`}
          >
            View Order in DB
          </Button>
        </Box>
        {processing && (
          <Backdrop open={true} style={{ zIndex: "1000", color: "#fff" }}>
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
      </Container>
    );
  }
}

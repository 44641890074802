import React from "react";
import { Box, Grid, Link, Paper, Typography } from "@mui/material";
import { capitalize } from "../../common/component/util";
import moment from "moment";
import MetrobiDeliveryDetails from "./MetrobiDeliveryDetails";

export default function OrderFulfillment(props) {
  return (
    <Paper elevation={0}>
      <Box px={2} py={4}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="overline" color="textSecondary">
              {capitalize(props.type)} address
            </Typography>
          </Grid>
          <Grid item xs={6}>
            {props.order.location && (
              <Box>
                <Typography variant="body2">
                  {props.order.location.street}
                  {props.order.location.unit && `, ${props.order.location.unit}`}
                </Typography>
                <Typography variant="body2">
                  {props.order.location.city}, {props.order.location.state} {props.order.location.zip}
                </Typography>
                {props.type === "delivery" && (
                  <Link
                    component="a"
                    underline="always"
                    target="_blank"
                    sx={{ fontSize: "12px" }}
                    href={`https://www.google.com/maps/dir/?api=1&travelmode=driving&destination=${encodeURIComponent(
                      `${props.order.location.street}, ${props.order.location.city}, ${props.order.location.state} ${props.order.location.zip}`
                    )}`}
                  >
                    Get Directions
                  </Link>
                )}
              </Box>
            )}
            {!props.order.location && props.order.address && (
              <Box>
                <Typography variant="body2" color="error">
                  {props.order.address}
                </Typography>
                {props.type === "delivery" && props.order.address && (
                  <Link
                    component="a"
                    underline="always"
                    target="_blank"
                    sx={{ fontSize: "12px" }}
                    href={`https://www.google.com/maps/dir/?api=1&travelmode=driving&destination=${encodeURIComponent(props.order.address)}`}
                  >
                    Get Directions
                  </Link>
                )}
              </Box>
            )}
          </Grid>
          <Grid item xs={6}>
            <Typography variant="overline" color="textSecondary">
              {capitalize(props.type)} date & time
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2">
              {moment(props.order.date).format("ddd, MMM DD, YYYY")} [{props.order.time}]
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="overline" color="textSecondary">
              {capitalize(props.type)} instructions
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2">{props.order.instructions}</Typography>
          </Grid>
        </Grid>
        {props.order.provider && <MetrobiDeliveryDetails provider={props.order.provider} />}
      </Box>
    </Paper>
  );
}

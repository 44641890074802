import React, { useState, useEffect } from "react";
import {
  Alert,
  Avatar,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Container,
  Divider,
  FormControlLabel,
  Grid,
  Link,
  LinearProgress,
  Paper,
  Switch,
  TextField,
  Typography
} from "@mui/material";
import bkstApi from "../../common/component/api";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router";
import PricingVariant from "../component/PricingVariant";
import ProductRules from "../component/ProductRules";
import { ExpandMoreOutlined } from "@mui/icons-material";
import Images from "../../common/component/Images";
import ProductTypeAutocomplete from "../component/ProductTypeAutocomplete";
import { Link as RouterLink } from "react-router-dom";
import { BAKER_PIC_FOLDER } from "../../common/component/constants";
import QuillRTE from "../../common/component/QuillRTE";
import ProductFlavors from "../component/ProductFlavors";
import currency from "currency.js";
import moment from "moment";

export default function ProductEntry(props) {
  const { siteId, productId } = useParams();
  const navigate = useNavigate();

  const [product, setProduct] = useState("");

  const [type, setType] = useState("");
  const [images, setImages] = useState([]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [options, setOptions] = useState("");
  const [customization, setCustomization] = useState("");
  const [rules, setRules] = useState("");
  const [status, setStatus] = useState("ACTIVE");

  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (productId) {
      setLoading(true);
      bkstApi(`/admin/site/${siteId}/product/${productId}`).then((res) => {
        setProduct(res.data);

        setType(res.data.type);
        setImages(res.data.images);
        setTitle(res.data.title);
        setDescription(res.data.description);

        setOptions(res.data.options);
        setCustomization(res.data.customization);

        setRules(res.data.rules);
        setStatus(res.data.status);

        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, [productId]);

  const handleDelete = () => {
    bkstApi.delete(`/admin/site/${siteId}/product/${productId}`).then((res) => {
      navigate(`/baker/${siteId}?tab=2`);
    });
  };

  const generateVariantPricingSet = () => {
    let arr = [...options.size];
    let single = arr.filter((o) => o.size === "Single");
    if (single && single.length === 1) {
      console.log(single);
      const basePrice = single[0].pricing.amount;
      arr.push({ size: "Set of 6", pricing: { type: "fixed", amount: currency(+basePrice * 6).value } });
      arr.push({ size: "Set of 12", pricing: { type: "fixed", amount: currency(+basePrice * 12).value } });
      setOptions({ ...options, size: arr });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // validate
    let arr = [];
    if (status === "ACTIVE") {
      if (rules?.date?.max) {
        if (moment().isAfter(moment(rules?.date?.max), "day")) {
          arr.push("Check Advanced Options. Max date can't be in the past");
        }

        if (rules?.date?.min && moment(rules.date.min).isAfter(moment(rules?.date?.max), "day")) {
          arr.push("Check Advanced Options. Min date can't be after Max date");
        }
      }
    }

    setErrors(arr);
    if (arr.length > 0) {
      return;
    }

    const payload = {
      type,
      images,
      title,
      description,
      options,
      //tags,
      rules,
      customization,
      status
    };

    if (productId) {
      bkstApi.put(`/admin/site/${siteId}/product/${productId}`, payload).then((res) => {
        navigate(`/baker/${siteId}?tab=2`);
      });
    } else {
      bkstApi.post(`/admin/site/${siteId}/product`, payload).then((res) => {
        navigate(`/baker/${siteId}?tab=2`);
      });
    }
  };

  if (loading) {
    return <LinearProgress />;
  } else {
    return (
      <Container maxWidth="sm">
        <Box my={6}>
          <Box mb={6} align="center">
            <Typography variant="h3" gutterBottom>
              Online Shop Item
            </Typography>
            <Typography variant="body1" color="textSecondary" gutterBottom>
              Be sure to include high-quality photos, item description, size & servings details to ensure customers feel confident ordering online
              from you.
            </Typography>
          </Box>
          <form onSubmit={handleSubmit}>
            <Link to={`/baker/${siteId}`} component={RouterLink} style={{ color: "inherit" }}>
              <Avatar src={`${BAKER_PIC_FOLDER}/${siteId}/logo400.webp`} alt="" style={{ color: "inherit", height: "24px", width: "24px" }} />
            </Link>
            <Paper elevation={0}>
              <Box px={2} pt={4} pb={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={3}>
                        <Images edit={true} pics={images} onChange={(arr) => setImages(arr)} folder={`img/site/${siteId}/tmp`} />
                      </Grid>
                      <Grid item xs={9}>
                        <ProductTypeAutocomplete value={type} label="Product Type" onChange={(value) => setType(value)} required />
                        <Box mt={3}>
                          <TextField
                            id="title"
                            value={title}
                            label="Title"
                            name="title"
                            variant="outlined"
                            fullWidth
                            required
                            onChange={(e) => setTitle(e.target.value)}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item sm={12} xs={12}>
                    <QuillRTE value={description} onChange={(html) => setDescription(html)} />
                  </Grid>
                </Grid>
              </Box>
            </Paper>
            <Box my={4}>
              <PricingVariant productType={type} value={options?.size || []} onChange={(data) => setOptions({ ...options, size: data })} />
              <Button size="small" fullWidth onClick={generateVariantPricingSet}>
                Generate Sets
              </Button>
            </Box>
            <Box my={4}>
              <Box>
                <Grid container alignItems="center">
                  <Grid item xs={9}>
                    <Typography variant="h6">Options</Typography>
                  </Grid>
                  <Grid item xs={3} align="right"></Grid>
                </Grid>
              </Box>
              <Paper elevation={0}>
                <Box px={2} pt={3} pb={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={Boolean(options?.flavor)}
                            onChange={(e) => {
                              let o = { ...options };
                              if (e.target.checked) {
                                o.flavor = product?.options?.flavor || [];
                              } else {
                                delete o.flavor;
                              }
                              setOptions(o);
                            }}
                          />
                        }
                        label="Allow customer to select a flavor"
                      />
                      {options?.flavor && (
                        <Box my={2} sx={{ paddingLeft: "3rem" }}>
                          <Paper variant="outlined">
                            <ProductFlavors type="Flavor" value={options.flavor} onChange={(data) => setOptions({ ...options, flavor: data })} />
                          </Paper>
                        </Box>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={Boolean(options?.filling)}
                            onChange={(e) => {
                              let o = { ...options };
                              if (e.target.checked) {
                                o.filling = product?.options?.filling || [];
                              } else {
                                delete o.filling;
                              }
                              setOptions(o);
                            }}
                          />
                        }
                        label="Allow customer to select a filling"
                      />
                      {options?.filling && (
                        <Box my={2} sx={{ paddingLeft: "3rem" }}>
                          <Paper variant="outlined">
                            <ProductFlavors type="Filling" value={options.filling} onChange={(data) => setOptions({ ...options, filling: data })} />
                          </Paper>
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
            </Box>
            <Box my={4}>
              <Box>
                <Grid container alignItems="center">
                  <Grid item xs={9}>
                    <Typography variant="h6">Customization</Typography>
                  </Grid>
                  <Grid item xs={3} align="right"></Grid>
                </Grid>
              </Box>
              <Paper elevation={0}>
                <Box px={2} pt={3} pb={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={Boolean(customization?.inscription)}
                            onChange={(e) => {
                              let o = { ...customization };
                              if (e.target.checked) {
                                o.inscription = { text: "" };
                              } else {
                                delete o.inscription;
                              }
                              setCustomization(o);
                            }}
                          />
                        }
                        label="Inscription - allow customer to add an inscription"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={Boolean(customization?.photo)}
                            onChange={(e) => {
                              let o = { ...customization };
                              if (e.target.checked) {
                                o.photo = { limit: 5 };
                              } else {
                                delete o.photo;
                              }
                              setCustomization(o);
                            }}
                          />
                        }
                        label="Photo - allow customer to upload photos"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={Boolean(customization?.notes)}
                            onChange={(e) => {
                              let o = { ...customization };
                              if (e.target.checked) {
                                o.notes = { text: "" };
                              } else {
                                delete o.notes;
                              }
                              setCustomization(o);
                            }}
                          />
                        }
                        label="Special Request - allow customer to add a note"
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
            </Box>
            <Box my={4}>
              <Box>
                <Grid container alignItems="center">
                  <Grid item xs={9}>
                    <Typography variant="h6">Availability</Typography>
                  </Grid>
                  <Grid item xs={3} align="right"></Grid>
                </Grid>
              </Box>
              <Paper elevation={0}>
                <Box px={2} pt={3} pb={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={<Switch checked={status === "ACTIVE"} onChange={(e) => setStatus(e.target.checked ? "ACTIVE" : "DRAFT")} />}
                        label="Show this item on my online store"
                      />
                    </Grid>
                  </Grid>
                  {status === "ACTIVE" && (
                    <Box mt={3}>
                      <Divider />
                      <Accordion elevation={0}>
                        <AccordionSummary expandIcon={<ExpandMoreOutlined />}>
                          <Box>
                            <Typography variant="h6">Advanced Options</Typography>
                            <Typography variant="body2" color="textSecondary">
                              Limit availability by date range & lead time
                            </Typography>
                          </Box>
                        </AccordionSummary>
                        <AccordionDetails sx={{ marginLeft: "15px", borderLeft: "3px solid #EEE" }}>
                          <ProductRules value={rules} onChange={(data) => setRules(data)} />
                        </AccordionDetails>
                      </Accordion>
                    </Box>
                  )}
                </Box>
              </Paper>
            </Box>
            <Box my={4}>
              {errors?.length > 0 && (
                <Box my={2}>
                  <Alert severity="error">{errors.join(". ")}</Alert>
                </Box>
              )}
              <Box sx={{ display: "flex", gap: "10px" }}>
                <Button variant="outlined" color="secondary" onClick={() => navigate(`/baker/${siteId}?tab=2`)} fullWidth>
                  Cancel
                </Button>
                <Button type="submit" variant="contained" color="primary" fullWidth disabled={options?.size?.length === 0}>
                  Save Item
                </Button>
              </Box>
            </Box>
          </form>
          <Box>
            <pre style={{ fontSize: "10px", color: "#FFF", backgroundColor: "#000", textAlign: "left" }}>{JSON.stringify(product, null, 2)}</pre>

            <Button
              disableElevation
              variant="text"
              color="secondary"
              fullWidth
              target="_blank"
              href={`https://console.firebase.google.com/project/bakestreet-f55a0/firestore/databases/-default-/data/~2Fbaker~2F${siteId}~2Fproduct~2F${productId}`}
            >
              View in DB
            </Button>
          </Box>
          {/*  <Box>
            <Button disableElevation variant="outlined" color="error" fullWidth onClick={handleDelete}>
              Delete
            </Button>
          </Box> */}
        </Box>
      </Container>
    );
  }
}

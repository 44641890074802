import React, { useState, useEffect } from "react";
import { Box, Container, Chip, Grid, LinearProgress, Link, Typography, Avatar, Paper } from "@mui/material";
import bkstApi from "../../common/component/api";
import { Link as RouterLink } from "react-router-dom";
import currency from "currency.js";
import moment from "moment";
import { sortByOrderDateTime } from "../../common/component/util";
import { STORAGE_BUCKET } from "../../common/component/constants";
import CountUp from "../../common/component/animate/CountUp";

const FILTERS = ["recent", "upcoming", "open"];

export default function Dashboard(props) {
  const [filter, setFilter] = useState("recent");
  const [fromDt, setFromDt] = useState("");
  const [toDt, setToDt] = useState("");

  const [data, setData] = useState([]);

  const [orders, setOrders] = useState([]);
  const [summary, setSummary] = useState();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let from = "";
    let to = "";

    if (filter === "recent") {
      from = moment().subtract(1, "days").format("YYYY-MM-DD");
    } else if (filter === "open") {
    } else if (filter === "upcoming") {
      from = moment().format("YYYY-MM-DD");
      to = moment().add(2, "days").format("YYYY-MM-DD");
    } else {
      from = moment().format("YYYY-MM-DD");
      to = moment().add(28, "days").endOf("isoweek").format("YYYY-MM-DD");
    }

    setFromDt(from);
    setToDt(to);

    search(from, to);
  }, [filter]);

  const search = (from, to) => {
    setLoading(true);

    bkstApi.get(`/admin/bakers?status=onboard`).then((res) => {
      setData(res.data);
      setLoading(false);
    });
  };

  if (loading) {
    return <LinearProgress />;
  } else {
    return (
      <Box my={7}>
        <Container>
          <Box mb={2}>
            <Typography variant="h1">{data.length} 🚀</Typography>
          </Box>
          <Grid container spacing={3}>
            {data.map((o) => (
              <Grid item xs={12} sm={3}>
                <Paper elevation={0}>
                  <Box p={2} textAlign={"center"}>
                    <Box mb={1} sx={{ display: "flex", justifyContent: "center" }}>
                      <Avatar src={`${STORAGE_BUCKET}/img/b/${o.id}/logo400.webp`} />
                    </Box>
                    <Typography variant="caption">{o.bizName}</Typography>
                  </Box>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
    );
  }
}

import React, { useEffect, useState } from "react";
import { Box, Button, Container, Grid, LinearProgress, Tab, Tabs, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import ProductSummary from "./ProductSummary";
import bkstApi from "../../common/component/api";
import { useLocation } from "react-router-dom";
import { capitalize } from "../../common/component/util";

export default function OnlineShop(props) {
  let location = useLocation();
  const { siteId } = props;

  const [filter, setFilter] = useState("active");

  const [products, setProducts] = useState([]);

  const [category, setCategory] = useState("");
  const [categoryMap, setCategoryMap] = useState("");

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    bkstApi(`/admin/site/${siteId}/product?filter=${filter}`).then((res) => {
      let sortedByRank = res.data.products.sort((o1, o2) => {
        let rslt = (+o2.rank || 0) - (+o1.rank || 0);
        if (rslt === 0) {
          rslt = o1.title > o2.title ? 1 : -1;
        }
        return rslt;
      });
      setProducts(sortedByRank);

      let map = {};
      for (const p of sortedByRank) {
        const cat = p.type.toLowerCase();
        const arr = map[cat] || [];
        arr.push(p);
        map[cat] = arr;
      }
      setCategoryMap(map);
      setFilter(filter);
      setLoading(false);

      if (window.location.hash) {
        setTimeout(() => {
          setCategory(window.location.hash.substring(1));
          scrollToCategory(window.location.hash.substring(1).replace("-", " "));
        }, 500);
      } else {
        window.scrollTo(0, 0);
      }
    });
  }, [location.key, filter]);

  const scrollToCategory = (cat) => {
    const el = document.getElementById(cat);
    if (el) {
      el.scrollIntoView();
    }
  };

  const sortCategories = (o1, o2) => {
    if (o1.includes("Featured")) {
      return -1;
    } else if (o2.includes("Featured")) {
      return 1;
    } else {
      return o1.localeCompare(o2);
    }
  };

  if (loading) {
    return <LinearProgress />;
  } else {
    return (
      <Box>
        <Container maxWidth="md">
          <Box mt={6}>
            <Box>
              <Box sx={{ display: "flex", gap: "10px", justifyContent: "space-between" }}>
                <Typography variant="h3">Online Shop</Typography>
                <Button variant="contained" color="primary" component={RouterLink} to={`/site/${siteId}/product/add`}>
                  Add Item
                </Button>
              </Box>
              <Box my={2}>
                <Tabs value={filter} variant="scrollable" scrollButtons="auto" allowScrollButtonsMobile sx={{ borderBottom: "1px solid #ECECEC" }}>
                  <Tab value="active" label="Active" onClick={() => setFilter('active')} />
                  <Tab value="draft" label="Draft" onClick={() => setFilter('draft')} />
                </Tabs>
              </Box>
            </Box>
            <Box
              mt={4}
              mb={6}
              sx={{
                display: "flex",
                backgroundColor: "#FEFEFE",
                position: "-webkit-sticky",
                position: "sticky",
                top: "0",
                border: "1px solid #ECECEC"
              }}
            >
              <Tabs
                value={false}
                variant="scrollable"
                scrollButtons="auto"
                allowScrollButtonsMobile
                sx={{
                  ".MuiButtonBase-root": {
                    padding: "7px"
                  }
                }}
              >
                {Object.keys(categoryMap)
                  .sort(sortCategories)
                  .map((cat, idx) => (
                    <Tab key={idx} label={cat} onClick={() => scrollToCategory(cat)} />
                  ))}
              </Tabs>
            </Box>
            {/*products.length > 0 && (
              <Box my={2}>
                <Typography variant="overline" color="textSecondary">
                  Showing {products.length} items
                </Typography>
              </Box>
            )*/}
            {Object.keys(categoryMap)
              .sort(sortCategories)
              .map((key) => {
                return (
                  <Box mb={4} id={key} sx={{ scrollMarginTop: "75px" }}>
                    <Box mb={2} textAlign={"left"}>
                      <Typography variant="h5">{capitalize(key)}</Typography>
                    </Box>
                    <Grid container spacing={3}>
                      {categoryMap[key].map((product, index) => {
                        return <ProductSummary key={index} product={product} />;
                      })}
                    </Grid>
                  </Box>
                );
              })}
          </Box>
        </Container>
      </Box>
    );
  }
}
